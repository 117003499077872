import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: 300,
    margin: "auto",
  },
  media: {
    height: 300,
    width: "100%",
  },
  name: {
    textDecoration: "none",
  },
});

const CardUi = ({ image, name, url, api, status = null }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <a className={classes.name} rel="noopener noreferrer" href={url}>
        {isMobile ? (
          <Card style={{ width: "350px", margin: "0 auto" }}>
            <CardActionArea>
              <CardMedia
                style={{
                  width: "95px",
                  height: "95px",
                  position: "absolute",
                }}
                image={image}
              ></CardMedia>
              <Typography
                align="center"
                gutterBottom
                style={{
                  marginLeft: "100px",
                  width: "250px",
                  height: "95px",
                  marginBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {name}
              </Typography>
            </CardActionArea>
          </Card>
        ) : (
          <>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia className={classes.media} image={image} />
                <CardContent>
                  <Typography
                    align="center"
                    gutterBottom
                    variant="h6"
                    component="h3"
                  >
                    {name}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {!status ?? (
                <Typography align="center" component="div">
                  {status}
                </Typography>
              )}
            </Card>
            {!api ? null : (
              <div style={{ width: "300px", margin: "auto" }}>
                <Button
                  href={url}
                  variant="contained"
                  color="primary"
                  style={{ width: "47%", margin: "1%" }}
                >
                  UI
                </Button>
                <Button
                  href={api}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  color="primary"
                  style={{ width: "47%", margin: "1%" }}
                >
                  API
                </Button>
              </div>
            )}
          </>
        )}
      </a>
    </>
  );
};

export default CardUi;
