import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";
import config from "../config.json";
import rainbowBar from "../assets/images/rainbow_bar.png";

export default function Footer() {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      fontSize: "0.8em",
      color: "black",
      textAlign: "center",
      backgroundColor: lightBlue[500],
    },
    border: {
      position: "fixed",
      left: 0,
      bottom: 0,
      height: "1.4em",
      width: "100%",
      backgroundImage: `url(${rainbowBar})`,
      backgroundSize: "100% 100%",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.border}>
      <div className={classes.root}>{config.Site.buildNum}</div>
    </div>
  );
}
