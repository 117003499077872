import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Switch } from "@material-ui/core";
import NightsStayTwoToneIcon from "@material-ui/icons/NightsStayTwoTone";
import rainbowBar from "../assets/images/rainbow_bar.png";

const useStyles = makeStyles(() => ({
  appbar: {
    justifyContent: "center",
  },
  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    transform: "translateX(10px)",
  },
  moon: {
    marginBottom: 4,
  },
  switch: {
    marginLeft: 5,
  },
  h2: {
    textAlign: "center",
  },
  border: {
    height: ".3em",
    backgroundImage: `url(${rainbowBar})`,
    backgroundSize: "100% 100%",
  },
}));

export default function MenuBar(props) {
  const classes = useStyles();
  const { checked, switchTheme } = props;

  const label = { inputProps: { "aria-label": "Dark mode" } };

  return (
    <div>
      <AppBar className={classes.appbar} color="primary" position="static">
        <Toolbar>
          <div className={classes.center}>
            <h2 className={classes.h2}>Comcast UEM Tools & Automations</h2>
          </div>
          <Switch
            {...label}
            className={classes.switch}
            size="small"
            checked={checked}
            onChange={switchTheme}
          />
          <NightsStayTwoToneIcon className={classes.moon} fontSize="small" />
        </Toolbar>
      </AppBar>
    </div>
  );
}
