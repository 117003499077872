import React from "react";
import { orange, lightBlue } from "@material-ui/core/colors";

// For Switch Theming
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const Theme = (props) => {
  const { children, darkState } = props;
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = lightBlue[500];
  const mainSecondaryColor = orange[500];
  const darkTheme = createTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });

  return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
};

export default Theme;
