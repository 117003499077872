import { useEffect } from "react";
import config from "./config.json";

const usePlausibleAnalytics = () => {
  useEffect(() => {
    const domain = config.domain;
    if (!domain) {
      console.error("Domain not set for Plausible Analytics");
      return;
    }

    const script = document.createElement("script");
    script.defer = true;
    script.setAttribute("data-domain", domain);
    script.src = "https://plausible.uem.comcast.net/js/script.js";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default usePlausibleAnalytics;
