import React from "react";
import CardUi from "./components/Card";
import { Grid, CssBaseline, Button } from "@material-ui/core";
import Sam from "./assets/images/SAM.png";
import Approval from "./assets/images/AppApproval.png";
import MemHelper from "./assets/images/MemWebhelper.png";
import Locations from "./assets/images/location.jpg";
import Snis from "./assets/images/snis.png";
import MenuBar from "./components/MenuBar";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./components/Footer";
import Theme from "./shared/Theme";
import config from "./config.json";

const useStyles = makeStyles({
  root: {
    marginTop: 50,
    marginRight: 10,
    marginLeft: 10,
  },
  gridItem: {
    marginBottom: 15,
  },
});

function App() {
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode") || "true"
  );
  React.useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  //Convert to and from a boolean for ease of use elsewhere
  const darkModeBool = JSON.parse(darkMode);
  const updateDarkMode = (e) => {
    setDarkMode(e.target.checked.toString());
  };
  const classes = useStyles();

  //List of sites to display
  const sites = [
    {
      image: Approval,
      name: "App Approval",
      url: `https://appapproval.${config.domain}/`,
    },
    {
      image: Locations,
      name: "Locations",
      url: `https://locations.${config.domain}/`,
    },
    {
      image: MemHelper,
      name: "MEM Web",
      url: `https://ui.${config.domain}/`,
      api: `https://ui.api.${config.domain}/index.html`,
    },
    {
      image: Sam,
      url: `https://sam.${config.domain}/`,
      name: "Software Asset Management",
    },
    {
      image: Snis,
      name: "ServiceNow Integration Service (SNIS)",
      url: `https://snis.${config.domain}/`,
    },
  ];

  //Function to return cards for each site
  const siteCards = sites.map((site) => {
    return (
      <Grid className={classes.gridItem} item xs={12} sm={6} md={4} lg={3}>
        <CardUi
          image={site.image}
          name={site.name}
          url={site.url}
          api={site.api}
        />
      </Grid>
    );
  });

  return (
    <Theme darkState={darkModeBool}>
      <div>
        <CssBaseline />
        <MenuBar checked={darkModeBool} switchTheme={updateDarkMode} />
        <div className={classes.root}>
          <Grid container>{siteCards}</Grid>
        </div>
        <Button
          href={`https://hub.api.${config.domain}/`}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          size="large"
          color="primary"
          style={{
            position: "fixed",
            bottom: "2.5em",
            right: "2.5em",
            zIndex: 1000,
          }}
        >
          API Hub
        </Button>
        <Footer />
      </div>
    </Theme>
  );
}

export default App;
