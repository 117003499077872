import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import usePlausibleAnalytics from "./usePlausibleAnalytics";

const Site = () => {
  usePlausibleAnalytics(); // Add this line
  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

ReactDOM.render(<Site />, document.getElementById("root"));
